import React from "react";

const NotFoundPage = () => (
  <div className="container">
    <section className="section">
      <div className="content is-medium">
        <h1>No such page found!</h1>
        <p>Much sadness :(</p>
      </div>
    </section>
  </div>
);

export default NotFoundPage;
